import React from 'react';
import { Button, message } from 'antd';
import { navigate } from 'gatsby';
import axios from 'axios';

const Checkout = ({ plan, userId, planType, monthlyPrice }) => {
    const redirectToCheckout = (planType) => {
        message.info('Redirecting to payment page...');

        const data = JSON.stringify({
            clientReferenceId: userId,
            price: plan,
            planType,
        });

        const config = {
            method: 'post',
            url: `${process.env.GATSBY_BASE_URL}/createCheckoutLink`,
            headers: {
                'Content-Type': 'application/json',
            },
            data,
        };

        axios(config)
            .then(function (response) {
                const { url } = response.data;
                window.open(url);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <>
            <Button
                type={planType === 'monthly' && 'primary'}
                block
                onClick={() => redirectToCheckout(planType)}
                style={{
                    fontSize: 13,
                    fontWeight: 400,
                }}
            >
                {planType === 'monthly' && `Pay Monthly — $${monthlyPrice}`}

                {planType === 'yearly' &&
                    `Pay Annually — $${monthlyPrice * 12 - monthlyPrice * 2}`}
                {planType === 'lifetime' &&
                    `Pay Once for lifetime — $${monthlyPrice}`}
            </Button>
        </>
    );
};

export default Checkout;
