import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Link, navigate } from 'gatsby';
import { Button, message } from 'antd';
import { FireOutlined } from '@ant-design/icons';
import { FaRegBuilding } from 'react-icons/fa';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';

import { pricingPlans } from '../data/plans';

import '../styles/plan.scss';
import SEO from '../blocks/layout/seo';
import Checkout from '../blocks/checkout';
import FAQs from '../blocks/faq';
import FormContainer from '../blocks/layout/formContainer';
import { Firebase } from '../config/firebase';

const PricingPlans = () => {
    const [planType, setPlanType] = useState('yearly');
    const [loading, setLoading] = useState(false);
    const [userPlan, setUserPlan] = useState('Free');
    const [custID, setCustID] = useState('');

    const [uid, setUid] = useState('');

    function onChange(user) {
        if (user) {
            setUid(user.uid);
        } else {
            navigate('/login');
        }
    }

    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return (
        <>
            <SEO title="Pricing Plan — Form with Sheet" />
            <FormContainer menuKey="4" pageTitle="Plans">
                {loading ? (
                    'Loading...'
                ) : (
                    <div className="plan-page">
                        <h2>Plan & Pricing</h2>
                        <p>
                            Your current plan and options to upgrade for high
                            plan.
                        </p>
                        <>
                            {/* <div className="annually-note">
                                <b
                                    style={{
                                        backgroundColor: '#3b2fc9',
                                        color: '#fff',
                                        padding: '0px 3px',
                                        borderRadius: 3,
                                    }}
                                >
                                    Note:
                                </b>{' '}
                                1 credit = when you push(sync) 10 rows.
                            </div> */}
                            <div className="list-of-all-plans">
                                {pricingPlans.map((planObj, key) => (
                                    <div
                                        key={key}
                                        className={
                                            planObj.plan === 'Lifetime'
                                                ? 'plan-card popular-plan'
                                                : 'plan-card'
                                        }
                                    >
                                        {planObj.plan === 'Lifetime' && (
                                            <div className="popular-tag">
                                                {userPlan === planObj.plan ? (
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <BsFillBookmarkCheckFill
                                                            style={{
                                                                marginRight: 4,
                                                            }}
                                                        />{' '}
                                                        Active plan
                                                    </span>
                                                ) : (
                                                    <>
                                                        <FireOutlined /> Limited
                                                        20 slots only
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <div className="name">
                                            {planObj.plan}
                                        </div>
                                        <div className="price">
                                            $
                                            {planObj.plan === 'Lifetime'
                                                ? planObj.oneTimePrice
                                                : planObj.monthlyPrice}
                                            <span>
                                                {planObj.plan === 'Lifetime'
                                                    ? ' — Lifetime'
                                                    : ' — month'}
                                            </span>
                                        </div>
                                        <div
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                                __html: planObj.description,
                                            }}
                                        />
                                        {userPlan !== planObj.plan &&
                                            userPlan !== 'Free' && (
                                                <Button
                                                    type="primary"
                                                    block
                                                    // onClick={
                                                    //       makeUserPortalLink
                                                    // }
                                                    style={{
                                                        fontSize: 13,
                                                        fontWeight: 400,
                                                    }}
                                                >
                                                    Upgrade to {planObj.plan}{' '}
                                                    plan
                                                </Button>
                                            )}
                                        {userPlan === 'Free' &&
                                            planObj.plan !== 'Lifetime' && (
                                                <>
                                                    <Checkout
                                                        userId={`${uid}-${planObj.apiCalls}-${planObj.planType}-${planObj.storageLimit}-${planObj.formLimit}`}
                                                        btnText={`Pay with Debit or Credit Card`}
                                                        plan={
                                                            planObj.monthlyPlanId
                                                        }
                                                        planType="monthly"
                                                        monthlyPrice={
                                                            planObj.monthlyPrice
                                                        }
                                                        planName={planObj.plan}
                                                    />
                                                    <br />
                                                    <br />
                                                    <Checkout
                                                        userId={`${uid}-${planObj.apiCalls}-${planObj.planType}-${planObj.storageLimit}-${planObj.formLimit}`}
                                                        btnText={`Pay with Debit or Credit Card`}
                                                        plan={
                                                            planObj.yearlyPlanId
                                                        }
                                                        planType="yearly"
                                                        monthlyPrice={
                                                            planObj.monthlyPrice
                                                        }
                                                        planName={planObj.plan}
                                                    />
                                                </>
                                            )}
                                        {userPlan === 'Free' &&
                                            planObj.plan === 'Lifetime' && (
                                                <>
                                                    <Checkout
                                                        userId={`${uid}-${planObj.apiCalls}-${planObj.plan}`}
                                                        btnText={`Pay with Debit or Credit Card`}
                                                        plan={planObj.planId}
                                                        planType="lifetime"
                                                        monthlyPrice={
                                                            planObj.oneTimePrice
                                                        }
                                                        planName={planObj.plan}
                                                    />
                                                </>
                                            )}
                                        <ul className="plan-features">
                                            {planObj.features.map((fe, key) => (
                                                <li>— {fe}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="request-quote">
                                <p>
                                    <FaRegBuilding />
                                    Need more credits? We also offer custom{' '}
                                    <b>
                                        <Link to="/contact">
                                            Enterprise plan
                                        </Link>
                                    </b>{' '}
                                    for unlimted credits, Dedicated server and
                                    more --{' '}
                                    <b>
                                        <Link to="/contact">Request Quote</Link>
                                    </b>
                                </p>
                            </div>
                            <FAQs />
                        </>
                    </div>
                )}
            </FormContainer>
        </>
    );
};

export default PricingPlans;
