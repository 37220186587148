export const faq = [
    {
        q: 'Do I need to know to Code?',
        a: 'No! this is the easiest and nocode tool to setup your form backend.',
    },
    {
        q: 'Do I have to pay to try it?',
        a: 'No! We give <b>300 free credits per month</b>. You can play around to see what premium plan suits you.',
    },
    {
        q: 'What happens, after I consume 100 % Credits?',
        a: 'Free users receive a <b>429 Error Code</b>. While Paid users, find everything working smooth. And we send you a reminder to upgrade your package email. You upgrade as soon as possible to make sure you do not interrupt your customers.',
    },
    {
        q: 'How do I upgrade my Subscription Plan?',
        a: 'Login to your dashboard and go to setting page to upgrade your plan.',
    },
    {
        q: 'What payment methods do we support?',
        a: 'Stripe (Supported cards: <b>Visa</b>, <b>Mastercard</b>, <b>American Express</b>) and Paypal (coming soon).',
    },
    {
        q: 'Can I get a refund?',
        a: 'Yes. If you contact us in <b>under 10 days</b> of your purchase. It is Full Refund without any questions.',
    },
];
